<!-- ./components/CardListComponent.vue -->
<template>
  <div class="card-list-container">
    <!-- Main content -->
    <div class="table-container" :class="{ 'has-deck': selectedDeck }">
      <!-- Search field -->
      <div class="search-bar">
        <input type="text" v-model="searchQuery" class="form-control" placeholder="Search cards..." />
      </div>

      <!-- Table for displaying card data -->
      <table class="table table-bordered custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Element</th>
            <th>Type</th>
            <th>Subtype</th>
            <th>Cost</th>
            <th>Attack</th>
            <th>Health</th>
            <th>Rules Text</th>
            <th v-if="selectedDeck">Add</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(card, index) in processedCards" :key="index">
            <td>{{ card.name }}</td>
            <td :style="getElementColumnStyle(card)" class="text-outline">
              {{ card.ElementDisplay }}
            </td>
            <td>{{ card.TypeDisplay }}</td>
            <td>{{ card.SubtypeDisplay }}</td>
            <td>{{ card.CostDisplay }}</td>
            <td>{{ card.attack }}</td>
            <td>{{ card.health }}</td>
            <td v-html="formatRulesText(card.RulesTextDisplay)"></td>

            <td v-if="selectedDeck">
              <button class="btn btn-primary btn-sm" @click="addCardToDeck(card)" :disabled="!canAddCardToDeck(card)">
                +
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Deck List Panel -->
    <div v-if="selectedDeck" class="deck-panel">
      <h3>{{ selectedDeck.name }} - ({{ totalCardCount }})</h3>
      <div class="deck-card" v-for="(card, index) in selectedDeck.cards" :key="index" @click="removeCardFromDeck(card)">
        {{ card.name }} ({{ card.count }})
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, computed } from "vue";

export default {
  name: "CardListComponent",
  setup() {
    const cardDataService = inject("cardData");
    const selectedDeck = inject("selectedDeck");
    const deckBox = inject("deckBox");
    const saveDeckBox = inject("saveDeckBox");

    const deepCopy = (obj) => {
      return JSON.parse(
        JSON.stringify(obj, (key, value) => (value === undefined ? null : value))
      );
    };

    const cardData = deepCopy(cardDataService);

    const elementColors = {
      Neutral: "#cccccc",
      Abyssal: "#45818e",
      Arcane: "#bd94a8",
      Force: "#dd7e6b",
      Light: "#f1c232",
      Noxious: "#8e7cc3",
      Storm: "#b6d7a8",
      Terra: "#f9cb9c",
      Thermal: "#6d9eeb",
    };

    const getElementColumnStyle = (card) => {
      const elements = card.element || [];
      if (elements.length === 1) {
        return { backgroundColor: elementColors[elements[0]] };
      } else if (elements.length > 1) {
        const gradientColors = elements
          .map((element) => elementColors[element] || "#ffffff")
          .join(", ");
        return {
          backgroundImage: `linear-gradient(to right, ${gradientColors})`,
        };
      }
      return {};
    };

    const searchQuery = ref("");

    const processedCards = computed(() => {
      // Step 1: Group cards by primary elements
      const groupedCards = {};
      cardData.forEach((card) => {
        if (!card.element) return;

        card.element.forEach((element) => {
          if (!groupedCards[element]) {
            groupedCards[element] = [];
          }
          groupedCards[element].push(card);
        });
      });

      // Step 2: Create a sorted list
      const sortedCards = [];
      Object.keys(groupedCards)
        .sort()
        .forEach((element) => {
          const singleElementCards = groupedCards[element].filter(
            (card) => card.element.length === 1
          );
          const multiElementCards = groupedCards[element].filter(
            (card) => card.element.length > 1
          );

          // Add single-element cards
          sortedCards.push(...singleElementCards);

          // Add multi-element cards
          multiElementCards.forEach((card) => {
            if (!sortedCards.includes(card)) {
              sortedCards.push(card);
            } else {
              sortedCards.push({ ...card });
            }
          });
        });

      // Step 3: Apply search query filter
      if (!searchQuery.value) {
        return sortedCards;
      }

      // Step 3: Apply search query filter with advanced search
      const query = searchQuery.value.trim();
      if (!query) {
        return sortedCards;
      }

      // If there is an unmatched quote, return the whole list.
      const quoteMatches = query.match(/"/g);
      if (quoteMatches && quoteMatches.length % 2 !== 0) {
        return sortedCards;
      }

      // Split the query into terms, grouping quoted phrases and removing the quotes
      const terms = query.match(/"([^"]+)"|(\S+)/g)
        .map(term => term.replace(/"/g, '').toLowerCase());

      return sortedCards.filter(card => {
        return terms.every(term => card.searchString.includes(term));
      });

    });


    const totalCardCount = computed(() => {
      if (!selectedDeck.value || !selectedDeck.value.cards) return 0;
      return selectedDeck.value.cards.reduce((sum, card) => sum + card.count, 0);
    });

    const addCardToDeck = (card) => {
      if (!selectedDeck.value) return;

      const existingCard = selectedDeck.value.cards.find(
        (deckCard) => deckCard.name === card.name
      );
      if (existingCard) {
        existingCard.count += 1;
      } else {
        selectedDeck.value.cards.push({ name: card.name, count: 1 });
      }

      saveDeckBox(deckBox.value);
    };

    const removeCardFromDeck = (card) => {
      if (!selectedDeck.value) return;

      const cardIndex = selectedDeck.value.cards.findIndex(
        (deckCard) => deckCard.name === card.name
      );

      if (cardIndex !== -1) {
        if (selectedDeck.value.cards[cardIndex].count > 1) {
          selectedDeck.value.cards[cardIndex].count -= 1;
        } else {
          selectedDeck.value.cards.splice(cardIndex, 1);
        }
      }

      saveDeckBox(deckBox.value);
    };

    const canAddCardToDeck = (card) => {
      if (!selectedDeck.value) return false;

      const existingCard = selectedDeck.value.cards.find(
        (deckCard) => deckCard.name === card.name
      );

      // If the card has NoCardLimitInd or is not in the deck, it can be added
      if (card.NoCardLimitInd || !existingCard) return true;

      // If the card is in the deck, check if its count is less than 3
      return existingCard.count < 3;
    };

    return {
      processedCards,
      searchQuery,
      getElementColumnStyle,
      addCardToDeck,
      removeCardFromDeck,
      canAddCardToDeck,
      selectedDeck,
      totalCardCount,
    };
  },
  methods: {
    formatRulesText(rulesText) {
      // Transform tags into corresponding images
      const transformTags = (text) => {
        // Replace [A], [R], etc., with their corresponding symbols
        text = text.replace(/\[([ARFLNSET])\]/g, (match, elementKey) => {
          const imgSrc = this.getCostImagePath(elementKey);
          return `<img src="${imgSrc}" alt="${elementKey}" class="rules-cost-image" />`;
        });

        // Replace [0], [10], [-1], etc., with their corresponding number symbols
        text = text.replace(/\[(-?\d+)\]/g, (match, number) => {
          const imgSrc = this.getCostImagePath(number);
          return `<img src="${imgSrc}" alt="${number}" class="rules-cost-image" />`;
        });

        return text;
      };

      // Replace newlines with <br> and apply transformations
      const textWithNewlines = rulesText.replace(/\n/g, "<br>");
      return transformTags(textWithNewlines);
    },


    getCostImagePath(costPiece) {
      const elementMapping = {
        A: "Abyssal",
        R: "Arcane",
        F: "Force",
        L: "Light",
        N: "Noxious",
        S: "Storm",
        E: "Terra",
        T: "Thermal",
      };

      // Check if the costPiece is an element
      const elementName = elementMapping[costPiece.toUpperCase()];
      if (elementName) {
        return `/symbol/Symbol${elementName}.png`;
      }

      // Check if the costPiece is a number (including negative numbers)
      if (!isNaN(parseInt(costPiece, 10))) {
        return `/symbol/Symbol${costPiece}.png`;
      }

      // Fallback for unmapped cases
      return `/symbol/SymbolNeutral.png`;
    },

    getCostCircleStyle(costPiece) {
      const elementColors = {
        Neutral: "#cccccc",
        Abyssal: "#45818e",
        Arcane: "#bd94a8",
        Force: "#dd7e6b",
        Light: "#f1c232",
        Noxious: "#8e7cc3",
        Storm: "#b6d7a8",
        Terra: "#f9cb9c",
        Thermal: "#6d9eeb",
      };


      const elementMapping = {
        A: "Abyssal",
        R: "Arcane",
        F: "Force",
        L: "Light",
        N: "Noxious",
        S: "Storm",
        E: "Terra",
        T: "Thermal",
      };

      const elementKey = costPiece.toUpperCase();
      const baseColor =
        elementMapping[elementKey] && elementColors[elementMapping[elementKey]]
          ? elementColors[elementMapping[elementKey]]
          : elementColors.Neutral;

      return {
        backgroundColor: baseColor,
      };
    },
  },

};
</script>
