// ./services/CardService.js
// This service provides card data transformations for use in the application.

import cardData from "../assets/cardData.json";

// Utility function to alphabetize a list of strings
function alphabetize(list) {
  return [...list].sort();
}

// Utility function to sort cost strings according to custom rules
function sortCostString(cost) {
  if (!cost) return null;

  const letters = [];
  const numbers = [];
  const xLetters = [];

  for (const char of cost) {
    if (/[a-wy-zA-WY-Z]/.test(char)) {
      letters.push(char);
    } else if (/[0-9]/.test(char)) {
      numbers.push(char);
    } else if (/[xX]/.test(char)) {
      xLetters.push(char);
    }
  }

  return [...letters.sort(), ...numbers, ...xLetters].join("");
}

// Utility function to calculate ConvertedCost
function calculateConvertedCost(cost) {
  if (!cost) return 0;

  let letterCount = 0;
  let numberSum = 0;
  let currentNumber = "";

  for (const char of cost) {
    if (/[a-wy-zA-WY-Z]/.test(char)) {
      letterCount++;
    } else if (/[0-9]/.test(char)) {
      currentNumber += char; // Build multi-character numbers
    } else {
      if (currentNumber) {
        numberSum += parseInt(currentNumber, 10); // Add the number if complete
        currentNumber = ""; // Reset for the next number
      }
    }
  }

  // Add any remaining number
  if (currentNumber) {
    numberSum += parseInt(currentNumber, 10);
  }

  return letterCount + numberSum;
}

// Utility function to split cost into pieces
function splitCostPieces(costDisplay) {
  if (!costDisplay) return [];

  const costPieces = [];
  let currentNumber = "";

  for (const char of costDisplay) {
    if (/[a-wy-zA-WY-Z]/.test(char)) {
      if (currentNumber) {
        costPieces.push(currentNumber); // Add the completed number
        currentNumber = ""; // Reset for the next number
      }
      costPieces.push(char); // Add the letter
    } else if (/[0-9]/.test(char)) {
      currentNumber += char; // Build multi-character numbers
    } else if (/[xX]/.test(char)) {
      if (currentNumber) {
        costPieces.push(currentNumber); // Add the completed number
        currentNumber = ""; // Reset for the next number
      }
      costPieces.push(char); // Add the X
    }
  }

  // Add any remaining number
  if (currentNumber) {
    costPieces.push(currentNumber);
  }

  return costPieces;
}

// Utility function to create Provides array
function buildProvides(costSplit) {
  if (!costSplit || costSplit.length === 0) return [];

  const providesSet = new Set();

  for (const piece of costSplit) {
    // Ignore numbers, X, and #
    if (/[0-9xX#]/.test(piece)) {
      continue;
    }
    providesSet.add(piece);
  }

  // If no entries were added, include #
  if (providesSet.size === 0) {
    providesSet.add("#");
  }

  return Array.from(providesSet);
}


// Utility function to capitalize the first letter of each line
function capitalizeFirstLetterEachLine(text) {
  return text
    .split("\n")
    .map((line) => line.charAt(0).toUpperCase() + line.slice(1))
    .join("\n");
}

// Utility function to normalize and replace keywords in RulesTextDisplay
function buildRulesTextDisplay(card) {
  const normalizedFields = [
    card.exportableText,
    card.chargeAbility ? `Charge Ability: ${card.chargeAbility}` : null,
    card.catacombsAbility ? `Catacombs Ability: ${card.catacombsAbility}` : null,
    card.abyssAbility ? `Abyss Ability: ${card.abyssAbility}` : null,
  ];

  let rulesText = normalizedFields
    .filter((field) => field !== null)
    .map((field) => field.replace(/\[NL\]/g, "\n")) // Replace [NL] with newlines
    .join("\n"); // Join with newlines

  // Check for and set IsEtherealInd
  card.IsEtherealInd = /\[Ethereal\]/.test(rulesText);

  // Check for and set IsSleightInd
  card.IsSleightInd = /\[Sleight\]/.test(rulesText);

  // Check for and set NoCardLimitInd
  card.NoCardLimitInd = /\[anynumberindeck\]/.test(rulesText);

  //TODO: Set IsDayInd
  card.IsDayInd = /\[Day\]/.test(rulesText);

  //TODO: Set IsNightInd
  card.IsNightInd = /\[Night\]/.test(rulesText);

  // Perform replacements
  rulesText = rulesText
    .replace(/\[K\]/g, "attack")
    .replace(/\[H\]/g, "health")
    .replace(/\[D\]/g, "drain")
    .replace(/\[Ethereal\]/g, "") // Remove [Ethereal]
    .replace(/\[anynumberindeck\]/g, "decks can contain any number of this card\n")
    .replace(/\[Sleight\]/g, "sleight");

  // Capitalize the first letter of each line
  return capitalizeFirstLetterEachLine(rulesText);
}

// Utility function to build TypeDisplay
function buildTypeDisplay(card) {
  if (!card.type) return "";

  // Extract special types ("day" or "night") preserving original order
  const specialTypes = card.type.filter(t => /^(day|night)$/i.test(t));
  // Extract remaining types that are not "day" or "night"
  const otherTypes = card.type.filter(t => !/^(day|night)$/i.test(t));

  // Alphabetize the remaining types
  const sortedOtherTypes = alphabetize(otherTypes);

  // Build the type display array
  const typeDisplayArray = [];

  // Always put 'Ethereal' at the beginning if flagged
  if (card.IsEtherealInd) {
    typeDisplayArray.push("Ethereal");
  }

  // Add special types (like "day" or "night") in their original order
  typeDisplayArray.push(...specialTypes);

  // If the card is a construct, insert "Construct" immediately after the special types
  if (card.isConstruct) {
    typeDisplayArray.push("Construct");
  }

  // Append the remaining alphabetized types
  typeDisplayArray.push(...sortedOtherTypes);

  let typeDisplay = typeDisplayArray.join(" ");

  // Prepend 'Epic' if the card is epic
  if (card.isEpic) {
    typeDisplay = `Epic ${typeDisplay}`;
  }

  return typeDisplay;
}



const transformedCardData = cardData.map((card) => {
  // Alphabetize element array before creating ElementDisplay
  if (card.element) {
    card.element = alphabetize(card.element);
  }

  // Add new transformations
  card.ElementDisplay = card.element ? card.element.join(" ") : null;
  card.CostDisplay = sortCostString(card.cost);
  card.ConvertedCost = calculateConvertedCost(card.cost);
  card.CostSplit = splitCostPieces(card.CostDisplay); // Calculate CostSplit
  card.Provides = buildProvides(card.CostSplit); // Calculate Provides
  card.SubtypeDisplay = card.subtype ? card.subtype.join(" ") : null; // Add SubtypeDisplay

  // RulesTextDisplay must be calculated before TypeDisplay as it sets IsEtherealInd
  card.RulesTextDisplay = buildRulesTextDisplay(card);
  card.TypeDisplay = buildTypeDisplay(card);

  // Determine if the card is an interrupt
  card.IsInterruptInd = false; // Default to false
  if (
    (card.subtype && card.subtype.includes("Incantation")) ||
    card.IsSleightInd
  ) {
    card.IsInterruptInd = true;
  }

  // Do not provide defaults for other properties; retain null
  card.rulesText = card.rulesText || null;
  card.attack = card.attack !== null ? card.attack : null;
  card.health = card.health !== null ? card.health : null;

  card.count = 1;

  // Pre-calculate a combined, lowercased search string for efficient filtering
  card.searchString = [
    card.name,
    card.ElementDisplay,
    card.TypeDisplay,
    card.SubtypeDisplay,
    card.CostDisplay,
    card.RulesTextDisplay
  ]
    .filter(Boolean)
    .join(" ")
    .toLowerCase();

  return card;

});

// Sorting logic
const sortedCardData = transformedCardData.sort((a, b) => {
  // 1. Sort by element priority
  const elementPriority = (card) => {
    if (card.element.length === 1 && card.element[0] === "Neutral") return 0; // Neutral always first
    if (card.element.length > 1) return 2; // Dual-element cards at the end
    return 1; // Single-element cards
  };

  const priorityA = elementPriority(a);
  const priorityB = elementPriority(b);
  if (priorityA !== priorityB) {
    return priorityA - priorityB;
  }

  // 2. Handle dual-element cards separately
  if (priorityA === 2) {
    // Primary sort by ElementDisplay
    const elementComparison = a.ElementDisplay.localeCompare(b.ElementDisplay);
    if (elementComparison !== 0) {
      return elementComparison; // Group by ElementDisplay
    }
    // Secondary sort by ConvertedCost
    return a.ConvertedCost - b.ConvertedCost;
  }

  // 3. For single-element and Neutral, sort by element alphabetically
  if (priorityA !== 2) {
    const elementComparison = a.ElementDisplay.localeCompare(b.ElementDisplay);
    if (elementComparison !== 0) {
      return elementComparison;
    }
  }

  // 4. Finally, sort by ConvertedCost for single-element and Neutral cards
  return a.ConvertedCost - b.ConvertedCost;
});

export function getCardData(cardList = null) {
  if (!cardList) {
    return sortedCardData;
  }

  // Create a mapping of card names to their count values
  const cardCounts = Object.fromEntries(cardList.map((card) => [card.name, card.count || 1]));

  // Filter and map to include the count property
  return sortedCardData
    .filter((card) => card.name in cardCounts)
    .map((card) => ({
      ...card,
      count: cardCounts[card.name], // Set the count value from cardList
    }));
}
